'use server';

import ImgproxyModule from 'imgproxy';
import { GravityType } from 'imgproxy/dist/types';
import { serverOnly$ } from 'vite-env-only/macros';

// @ts-expect-error: weird fix until module supports ESM
const Imgproxy: typeof ImgproxyModule = ImgproxyModule.default;

const imgproxy = new Imgproxy({
  baseUrl: import.meta.env.VITE_IMGPROXY_SERVER,
  key: serverOnly$(import.meta.env.VITE_IMGPROXY_KEY) ?? '',
  salt: serverOnly$(import.meta.env.VITE_IMGPROXY_SALT) ?? '',
  encode: true,
});

export function optimize(url: string) {
  return imgproxy.builder().resize('fit', 0, 0, true).generateUrl(url);
}

const retinaThumbs = [3, 2, 1];

type BaseSize = `${number | ''}x${number | ''}`;

export function generateRetinaThumbs(url: string, baseSize: BaseSize) {
  const [baseWidth, baseHeight] = baseSize
    .split('x')
    .map(size => Number.parseInt(size || '0', 10));

  return retinaThumbs
    .map(scale => {
      return `${imgproxy
        .builder()
        .resize('fill', baseWidth * scale, baseHeight * scale, true)
        .gravity(GravityType.smart)
        .generateUrl(url)} ${scale.toString()}x`;
    })
    .join(', ');
}

const widthThumbs = [1920, 1440, 1152, 960, 840, 720, 576, 420, 288, 144];

export function generateWidthThumbs(url: string, ratio?: number) {
  return widthThumbs
    .map(
      width =>
        `${imgproxy
          .builder()
          .resize(
            'fill',
            width,
            ratio === undefined ? 0 : Math.round(width / ratio),
            true,
          )
          .gravity(GravityType.smart)
          .generateUrl(url, 'jpg')} ${width.toString()}w`,
    )
    .join(', ');
}
